<template>
  <div class="icon-bg">
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <rect x="2.41785" y="5" width="19" height="4" rx="1" fill="#A7A9C0" />
        <rect opacity="0.3" x="2.41785" y="11" width="19" height="10" rx="1" fill="#A7A9C0" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" transform="translate(0.41785)" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: "IconCalendar",
  mixins: [],
  props: {},
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";
.icon-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  flex-shrink: 0;
  transition: 0.1s;
  svg {
    width: 24px;
    height: 24px;
  }
  @media (hover: hover) {
    &:hover {
      background: #ebf2fa;

      svg {
        rect {
          fill: #5551f9;
        }
      }
    }
  }
  &:active {
    background: transparent;
    svg {
      rect {
        fill: #5551f9;
      }
    }
  }
}
</style>
